/* eslint-disable space-before-function-paren */
import axiosErrorHandler from './axiosErrorHandler.js'
import store from '@/store.js'
import { MEMBER_API_CONFIG } from '@/config/member.api.config.js'

export default {
  async getMemberProfileDetailsById (indKey) {
    const URL = MEMBER_API_CONFIG.getProfile(indKey)
    const response = await axiosErrorHandler({
      endpoint: URL,
      apiType: 'get'
    })
    return response
  },
  async getMemberCommitteeAndRoles (indKey) {
    const userLanguageKey = store.getters['user/userLanguageKey']
    const URL = MEMBER_API_CONFIG.getMemberCommitteeAndRoles(indKey, userLanguageKey)
    const response = await axiosErrorHandler({
      endpoint: URL,
      apiType: 'get'
    })
    return response
  }
}
