/* eslint-disable */
import MemberService from './memberService'
import { DeFormatUSPhoneNumber } from '@/services/utils/FormatUSPhoneNumber.js'
import { GIDEON_MEMBER_TYPE_KEY, AUXILIARY_MEMBER_TYPE_KEY } from '@/constants/MemberTypeKeys'

export default {
  async buildCustomBrushfireEventRegistrationURL (userIndKey, baseBrushfireEventURL) {
    // Brushfire event registration link
    const memberDetails = await MemberService.getMemberProfileDetailsById(userIndKey)

    if (!memberDetails) {
      return ''
    }

    const isUserGideonMember = memberDetails && memberDetails.member_type_key === GIDEON_MEMBER_TYPE_KEY
    const isUserAuxiliaryMember = memberDetails && memberDetails.member_type_key === AUXILIARY_MEMBER_TYPE_KEY

    const spouseIndKey = memberDetails.spouse_ind_key
    const spouseMemberDetails = spouseIndKey && (await MemberService.getMemberProfileDetailsById(spouseIndKey))
    const isSpouseGideonMember = spouseMemberDetails && spouseMemberDetails.member_type_key === GIDEON_MEMBER_TYPE_KEY
    const isSpouseAuxiliaryMember =
      spouseMemberDetails && spouseMemberDetails.member_type_key === AUXILIARY_MEMBER_TYPE_KEY

    if (isUserGideonMember && isSpouseAuxiliaryMember) {
      return this.generateCustomURL(baseBrushfireEventURL, memberDetails, spouseMemberDetails)
    } else if (isUserAuxiliaryMember && isSpouseGideonMember) {
      return this.generateCustomURL(baseBrushfireEventURL, spouseMemberDetails, memberDetails)
    } else if (isUserGideonMember) {
      return this.generateCustomURL(baseBrushfireEventURL, memberDetails, {})
    } else if (isUserAuxiliaryMember) {
      return this.generateCustomURL(baseBrushfireEventURL, {}, memberDetails)
    }
  },
  generateCustomURL (baseBrushfireEventURL, gideonMemberDetails, auxiliaryMemberDetails) {
    let url = `${baseBrushfireEventURL}?`
    const USCountryCode = '1'
    const uriEncodedPlusSign = '%2b' // %2b =>  +

    // GMN - Member Number
    if (gideonMemberDetails.member_number) {
      url += `GMN=${gideonMemberDetails.member_number}`
    }
    // GFN - Gideon First Name
    if (gideonMemberDetails.first_name) {
      //TODO: Remove middle name after confirmation from the client
      const firstName = gideonMemberDetails.first_name
      url += `&GFN=${firstName}`
    }
    // GLN - Gideon Last Name
    if (gideonMemberDetails.last_name) {
      url += `&GLN=${gideonMemberDetails.last_name}`
    }
    // GPN - Gideon Phone Number
    if (gideonMemberDetails.phone) {
      const deFormattedPhoneNumber = DeFormatUSPhoneNumber(gideonMemberDetails.phone)
      const isUSCountryCodeAlreadyAdded = deFormattedPhoneNumber.length === 11 && deFormattedPhoneNumber[0] === '1'
      url += `&GPN=${uriEncodedPlusSign}${!isUSCountryCodeAlreadyAdded ? USCountryCode : ''}${deFormattedPhoneNumber}`
    }
    // GEM - Gideon Email
    if (gideonMemberDetails.email) {
      url += `&GEM=${gideonMemberDetails.email}`
    }
    // Street Address - Primary
    if (gideonMemberDetails.address1) {
      url += `&STR=${gideonMemberDetails.address1}`
    }
    // City
    if (gideonMemberDetails.city) {
      url += `&CIT=${gideonMemberDetails.city}`
    }
    // State
    if (gideonMemberDetails.state) {
      url += `&STA=${gideonMemberDetails.state}`
    }
    // Zip Code
    if (gideonMemberDetails.postal_code) {
      url += `&ZIP=${gideonMemberDetails.postal_code}`
    }
    // Camp Name
    if (gideonMemberDetails.camp_name) {
      url += `&CNM=${gideonMemberDetails.camp_name}`
    }
    // Camp Number
    if (gideonMemberDetails.camp_number) {
      url += `&CNU=${gideonMemberDetails.camp_number}`
    }

    // AMN - Auxiliary Member Number
    if (auxiliaryMemberDetails.member_number && Object.keys(gideonMemberDetails).length > 0) {
      url += `&AMN=${auxiliaryMemberDetails.member_number}`
    } else if (auxiliaryMemberDetails.member_number && Object.keys(gideonMemberDetails).length === 0) {
      url += `AMN=${auxiliaryMemberDetails.member_number}`
    }
    // AFN - Auxiliary First Name
    if (auxiliaryMemberDetails.first_name) {
      //TODO: Remove middle name after confirmation from the client
      const firstName = auxiliaryMemberDetails.first_name + ' ' + (auxiliaryMemberDetails.middle_name ? auxiliaryMemberDetails.middle_name : '')
      url += `&AFN=${firstName}`
    }
    // ALN - Auxiliary Last Name
    if (auxiliaryMemberDetails.last_name) {
      url += `&ALN=${auxiliaryMemberDetails.last_name}`
    }
    // APN - Auxiliary Phone Number
    if (auxiliaryMemberDetails.phone) {
      const deFormattedPhoneNumber = DeFormatUSPhoneNumber(auxiliaryMemberDetails.phone)
      const isUSCountryCodeAlreadyAdded = deFormattedPhoneNumber.length === 11 && deFormattedPhoneNumber[0] === '1'
      url += `&APN=${uriEncodedPlusSign}${!isUSCountryCodeAlreadyAdded ? USCountryCode : ''}${deFormattedPhoneNumber}`
    }
    // AEM - Auxiliary Email
    if (auxiliaryMemberDetails.email) {
      url += `&AEM=${auxiliaryMemberDetails.email}`
    }

    if (Object.keys(gideonMemberDetails).length > 0) {
      return url
    }
    // Street Address - Primary
    if (auxiliaryMemberDetails.address1) {
      url += `&STR=${auxiliaryMemberDetails.address1}`
    }
    // City
    if (auxiliaryMemberDetails.city) {
      url += `&CIT=${auxiliaryMemberDetails.city}`
    }
    // State
    if (auxiliaryMemberDetails.state) {
      url += `&STA=${auxiliaryMemberDetails.state}`
    }
    // Zip Code
    if (auxiliaryMemberDetails.postal_code) {
      url += `&ZIP=${auxiliaryMemberDetails.postal_code}`
    }
    // Camp Name
    if (auxiliaryMemberDetails.camp_name) {
      url += `&CNM=${auxiliaryMemberDetails.camp_name}`
    }
    // Camp Number
    if (auxiliaryMemberDetails.camp_number) {
      url += `&CNU=${auxiliaryMemberDetails.camp_number}`
    }
    return url
  }
}
