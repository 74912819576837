/**
 * Formats a US phone number to (###)-###-#### *
 * @param {string} phoneNumber
 * @returns {string}
 */
function FormatUSPhoneNumber (phoneNumber) {
  if (!phoneNumber) return
  if (phoneNumber.length <= 3) return phoneNumber
  const middleThreeDigits = phoneNumber.substring(3, 6)
  const lastFourDigits = phoneNumber.substring(6, 10)
  return `(${phoneNumber.substring(0, 3)})${middleThreeDigits ? ' ' + middleThreeDigits : ''}${lastFourDigits ? '-' + lastFourDigits : ''}`
}

/**
 * De-format a US phone number from (###)-###-####  to ##########
 * @param {string} phoneNumber
 * @returns {string}
 */
function DeFormatUSPhoneNumber (phoneNumber) {
  if (!phoneNumber) return
  if (phoneNumber.length <= 3) return phoneNumber
  return phoneNumber.split('-').join('').split(')').join('').split('(').join('').replace(/ /g, '')
}

export { FormatUSPhoneNumber, DeFormatUSPhoneNumber }
